/* globals google */
import $ from 'jquery'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
// fix for Internet Explorer 11 Error: This site overrides Array.from() with an implementation that doesn't support iterables, which could cause Google Maps JavaScript API v3 to not work correctly
import 'core-js/modules/es.symbol.iterator'
import '../../assets/scripts/ExternalScriptLoader'

class MapLocations extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.locations = this.props.locations
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('#jsonData', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$slider = $('[data-slider]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$map = $('.map-wrapper', this)
    this.$controlsWrapper = $('.map-controls', this)
    this.$controls = $('[data-control]', this)
    this.$filters = $('.filters-select', this)
    this.$bannerImage = $('.location-bannerImage', this)
    this.$clearFiltersBtn = $('[data-clear-filters]', this)
    this.$consentLayer = $('.map-consent-layer', this)
    this.$consentLayerButton = $('.map-consent-layer .button', this)
  }

  connectedCallback () {
    this.$controlsWrapper.hide()
    this.$map.hide()
    this.$consentLayerButton.on('click', this.initMap.bind(this))
    this.initMap.bind(this)
    this.initSlider()
    this.$.on('change', '[data-filter-marker]', this.filterMarkers.bind(this))
    this.$.on('click', '[data-clear-filters]', this.resetFilters.bind(this))
    this.checkPaginationPos($('.swiper-slide-active', this.$slider))
  }

  initSlider () {
    const { options } = this.props
    const config = {
      navigation: {
        nextEl: $('.swiper-wrapper', this.$slider).children().length > 1 ? this.$buttonNext : '',
        prevEl: $('.swiper-wrapper', this.$slider).children().length > 1 ? this.$buttonPrev : ''
      },
      a11y: options.a11y,
      allowTouchMove: $('.swiper-wrapper', this.$slider).children().length > 1,
      autoHeight: true,
      runCallbacksOnInit: false,
      on: {
        slideChangeTransitionStart: () => {
          const $currentSlide = $(this.slider.slides[this.slider.activeIndex])
          this.checkPaginationPos($currentSlide)
        },
        slideChangeTransitionEnd: () => {
          const $currentSlide = $(this.slider.slides[this.slider.activeIndex])
          const currentMarker = this.markers.filter((marker) => {
            return marker.locationId === parseInt($currentSlide.attr('data-id'))
          })
          this.shrinkOtherMarkers()
          this.setIcon(currentMarker[0])
        }
      }
    }
    this.slider = new Swiper(this.$slider, config)
  }

  checkPaginationPos (slide) {
    const bannerHeight = slide.find('.location-bannerImage').length ? slide.find('.location-bannerImage').outerHeight() : 0
    this.$buttonNext.css('top', bannerHeight)
    this.$buttonPrev.css('top', bannerHeight)
    if ($('.swiper-wrapper', this.$slider).children().length > 1) {
      this.$buttonNext.addClass('visible')
      this.$buttonPrev.addClass('visible')
    }
  }

  initMap () {
    this.$consentLayer.hide()
    this.$controlsWrapper.show()
    this.$map.show()

    if (this.props.apiKey) {
      window.FlyntExternalScriptLoader
      .getInstance()
      .initialize('googleMaps', { apiKey: this.props.apiKey })
      .then(this.initGoogleMap.bind(this))
    }
  }

  setMapOptions () {
    let mapStyles = JSON.parse(this.props.mapStyles)
    if (mapStyles.invalid === true) {
      console.error('Could not load map styles! There is an error in your map styles JSON syntax.')
      mapStyles = []
    }
    this.mapSettings = {
      clickableIcons: true,
      disableDefaultUI: true,
      draggable: true,
      gestureHandling: 'greedy',
      heading: 0,
      keyboardShortcuts: false,
      panControl: true,
      rotateControl: false,
      mapTypeControl: false,
      scaleControl: false,
      scrollwheel: false,
      streetViewControl: false,
      fullscreenControl: false,
      tilt: 0,
      styles: mapStyles,
      center: {
        lat: Number(this.locations[0].map_latitude),
        lng: Number(this.locations[0].map_longitude)
      },
      zoom: 14
    }
  }

  initGoogleMap () {
    this.setMapOptions()
    this.map = new google.maps.Map(this.$map.get(0), this.mapSettings)
    this.addCustomMapControls()
    if (this.locations) {
      this.setMapLocations(this.locations)
      this.createOverlay()
    }
    this.setActiveMarker()
  }

  addCustomMapControls () {
    this.$controls.on('click', this.clickControl.bind(this))
    const $controlsWrapper = this.$controlsWrapper.clone(true)
    $controlsWrapper.addClass('map-controls-initialized')
    this.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push($controlsWrapper.get(0))
    this.$controlsWrapper.remove()
    this.$controlsWrapper = $controlsWrapper
  }

  clickControl (e) {
    const $el = $(e.currentTarget)
    const controlName = $el.data('control')
    if (controlName === 'zoomIn') {
      this.map.setZoom(this.map.getZoom() + 1)
    } else if (controlName === 'zoomOut') {
      this.map.setZoom(this.map.getZoom() - 1)
    } else if (controlName === 'satellite') {
      this.clickSatelliteButton(e)
    }
  }

  setMapLocations (locations) {
    this.markers = []
    const bounds = new google.maps.LatLngBounds()
    locations.forEach((location) => {
      const marker = this.createMarker(location)
      this.markers.push(marker)
      marker.addListener('click', this.hightlightMarker.bind(this, marker))

      if (locations.length > 1) {
        const latlng = marker.getPosition()
        bounds.extend(latlng)
        this.map.fitBounds(bounds)
      }
    })
  }

  createMarker (location) {
    const pinIcon = this.props.icon
    const pinColor = pinIcon.replace('%fillColor%', location.color)
    const iconUrl = 'data:image/svg+xml,' + encodeURIComponent(pinColor)
    return new google.maps.Marker({
      position: {
        lat: Number(location.map_latitude),
        lng: Number(location.map_longitude)
      },
      icon: {
        url: iconUrl,
        size: new google.maps.Size(44, 65),
        optimized: false, // IE11 fix
        scaledSize: new google.maps.Size(34, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 65)
      },
      title: location.post_title,
      locationId: location.id,
      locationArea: location.area,
      locationCompany: location.company,
      locationCompanyColor: location.color,
      locationCompanyId: location.companyId,
      locationAreaId: location.areaId,
      iconUrl,
      zIndex: 0,
      map: this.map
    })
  }

  createOverlay () {
    const myoverlay = new google.maps.OverlayView()
    myoverlay.draw = function () {
      this.getPanes().markerLayer.id = 'markerLayer'
    }
    myoverlay.setMap(this.map)
  }

  setActiveMarker () {
    const $currentSlide = this.$slider.find('.swiper-slide-active')
    const currentMarker = this.markers.filter((marker) => {
      return marker.locationId === parseInt($currentSlide.attr('data-id'))
    })
    this.shrinkOtherMarkers()
    this.setIcon(currentMarker[0])
  }

  hightlightMarker (marker) {
    const markerId = marker.locationId
    this.shrinkOtherMarkers()
    this.setIcon(marker)
    this.slideToCurrentSlide(markerId)
  }

  setIcon (marker) {
    marker.setIcon({
      url: marker.iconUrl,
      size: new google.maps.Size(44, 65),
      optimized: false, // IE11 fix
      scaledSize: new google.maps.Size(44, 65),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(0, 65)
    })
    marker.zIndex = 10
  }

  shrinkOtherMarkers () {
    this.markers.forEach(function (marker) {
      marker.zIndex = 0
      marker.setIcon({
        url: marker.iconUrl,
        size: new google.maps.Size(44, 65),
        optimized: false, // IE11 fix
        scaledSize: new google.maps.Size(34, 50),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(0, 65)
      })
    })
  }

  slideToCurrentSlide (markerId) {
    const findSlide = this.$slider.find(`.slide-${markerId}`)
    this.slider.slideTo(this.$slider.find('.swiper-slide').index(findSlide))
    $('html,body').animate({
      scrollTop: this.$slider.offset().top - ($('[is=flynt-navigation-main] > .wrapper').height() + 30)
    }, 'slow')
  }

  filterMarkers (e) {
    const $currentTarget = $(e.currentTarget)
    const filterThatChanged = $currentTarget.data('filterMarker')
    this.$clearFiltersBtn.addClass('filters-clearBtn--visible')
    let { locations } = this.props

    if (!this.activeFilter) {
      this.activeFilter = $currentTarget.data('filterMarker') // companies || areas
    }

    // areas active
    if (filterThatChanged === 'areas' && this.activeFilter === 'areas') {
      this.filteredLocations = locations.filter((location) => {
        return location.areaId === parseInt($currentTarget.find(':selected').val())
      })

      this.matchedCompanies = []
      for (let location of this.filteredLocations) {
        this.matchedCompanies.push(location.companyId)
      }

      this.updateFilter($('[data-filter-marker="companies"]', this), this.matchedCompanies)
    }

    // companies active
    if (filterThatChanged === 'companies' && this.activeFilter === 'companies') {
      this.filteredLocations = locations.filter((location) => {
        return location.companyId === parseInt($currentTarget.find(':selected').val())
      })

      this.matchedAreas = []
      for (let location of this.filteredLocations) {
        this.matchedAreas.push(location.areaId)
      }

      this.updateFilter($('[data-filter-marker="areas"]', this), this.matchedAreas)
    }

    if (filterThatChanged !== this.activeFilter) {
      this.filteredLocations = locations.filter((location) => {
        const companyId = parseInt($('[data-filter-marker="companies"]', this).find(':selected').val())
        const areaId = parseInt($('[data-filter-marker="areas"]', this).find(':selected').val())
        return location.companyId === companyId && location.areaId === areaId
      })
    }

    this.updateMapView(this.filteredLocations)
    this.updateSwiper(this.filteredLocations)
  }

  updateFilter ($filter, matches) {
    const $filterOptions = $filter.find('option')

    $filterOptions.each((i, area) => {
      const id = parseInt($(area).val())
      const state = matches.includes(id) ? false : 'disabled'
      $(area).attr('disabled', state)

      if (!state && matches.length === 1) {
        $filter.val(matches[0])
      }
    })

    if (matches.length > 1) {
      $filter.val(null)
    }
  }

  updateMapView (locations) {
    const locationIds = locations.map((location) => {
      return location.ID
    })

    const bounds = new google.maps.LatLngBounds()
    this.markers.forEach((marker, index) => {
      if (locationIds.includes(marker.locationId)) {
        marker.setVisible(true)
        // Create bounds from markers
        const latlng = marker.getPosition()
        bounds.extend(latlng)
        // Don't zoom in too far on only one marker
        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
          const extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.01, bounds.getNorthEast().lng() + 0.01)
          const extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.01, bounds.getNorthEast().lng() - 0.01)
          bounds.extend(extendPoint1)
          bounds.extend(extendPoint2)
        }
        this.map.fitBounds(bounds)
      } else {
        marker.setVisible(false)
      }
    })
  }

  resetFilters () {
    this.$clearFiltersBtn.removeClass('filters-clearBtn--visible')
    this.activeFilter = false
    const bounds = new google.maps.LatLngBounds()
    this.markers.forEach((marker, index) => {
      marker.setVisible(true)
      const latlng = marker.getPosition()
      bounds.extend(latlng)
      this.map.fitBounds(bounds)
    })
    $('[data-filter-marker] option').each((i, option) => {
      const $option = $(option)
      if ($option.index() !== 0) {
        $option.removeAttr('disabled')
      }
    })
    this.$filters.each((i, v) => $(v).val(null))
    this.$clearFiltersBtn.removeClass('filters-clearBtn--visible')
    const slides = this.$slider.find('.location')
    slides.addClass('swiper-slide').show()
    this.slider.update()
    this.slider.slideTo(0)
    this.$buttonNext.show()
    this.$buttonPrev.show()
    this.setActiveMarker()
  }

  updateSwiper (matches) {
    this.$slider.find('.swiper-slide').removeClass('swiper-slide').removeClass('swiper-slide-active').hide()
    for (let match of matches) {
      const matchedSlide = this.$slider.find(`.slide-${match.id}`)
      matchedSlide.addClass('swiper-slide').show()
    }
    this.slider.update()
    this.slider.slideTo(0)
    if (matches.length === 1) {
      this.$buttonNext.hide()
      this.$buttonPrev.hide()
    } else {
      this.$buttonNext.show()
      this.$buttonPrev.show()
    }
    this.setActiveMarker()
  }
}

window.customElements.define('flynt-map-locations', MapLocations, { extends: 'div' })
